/*
 * Filename: typo.css
 *
 * Primary stylesheet which imports every other file.
 */

body {
    font-family: var(--font-normal);
    font-size: var(--font-size);
    line-height: var(--line-height);
    color: var(--font-color);
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    margin-top: 0;
    margin-bottom: var(--gutter-horizontal);
}

h1, h2 {
    font-family: var(--font-heading);
}

h1 {
    font-family: var(--font-special);
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
    margin: 0;
}

.content-header-text h1 {
    color: #fff;
}

h2 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 1rem;
    color: var(--color-primary);
}

.text-ueber-bild--text--inner h2 {
    color: #fff;
}

h3 {
    font-family: var(--font-special);
    font-size: 24px;
    line-height: 30px;
    color: var(--font-color);
    margin: 0;
    font-weight: 400;
}

h4 {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: var(--font-color);
    text-align: center;
}

h5 {
    font-weight: 500;
    color: var(--font-color);
    font-size: 1.2rem;
    line-height: 1.6rem;

}

small {
    font-size: var(--font-size-small);
}

a {
    font-weight: 600;
}

.pure-g [class *= "pure-u"] {
    font-family: var(--font-normal);
}

.text-bold,
strong, b {
    font-weight: 900;
}

ul {
    padding-left: 24px;
}

li {
    margin-bottom: 0.4rem;
}

/* HEADER SLIDER */

.slider-wrapper.slider h1,
.slider-wrapper.slider p {
    color: #fff;
}

.slider-wrapper.slider p {
    font-size: 3.3rem;
    line-height: 3.8rem;
    font-weight: 900;
}


