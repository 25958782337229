.termin {
    padding: 60px 0 40px 0;
    text-align: center;
    background-color: #FAFAF8;
    background-image: url("img/fuss-hintergrund.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.termin::after {
    border-top: 7px double #E0E0DE;
    content: '';
    height: 16px;
    left: 50%;
    position: absolute;
    top: 30px;
    transform: translate(-50%, 50%);
    width: 300px;
}

.termin h2 {
    color: var(--color-primary);
    margin-bottom: 0;
}

.termin h3 {
    color: var(--font-color);
}



.termin .pure-g > div input,
.termin .pure-g > div select {
    border-radius: 0;
    margin-bottom: 4.8px;

}

.termin-image {
    text-align: left;
}

input.pure-input-1.input-date {
    height: 2.56rem;
}

.btn-container {
    text-align: left;
}

.termin .pure-form input[readonly],
.termin .pure-form select[readonly],
.termin .pure-form textarea[readonly] {
    background: #fff;
}

.termin__image-wrapper {
    display: none;
}

.termin__image-wrapper img {
    width: 100%;
    height: auto;
}

.termin .btn-container {
    text-align: center;
    margin-top: 40px;
}

.termin label {
    font-size: 14px;
    text-align: left;
    color: var(--font-color);
}

input.has-icon {
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    background-size: 26px;
}

input#datepicker {
    background-image: url("img/calendar.png");
}

input#terminemail {
    background-image: url("img/email-filled-closed-envelope.png");
}

input#terminphone {
    background-image: url("img/call-answer.png");
}

@media screen and (min-width: 35.5rem) {
    .termin .pure-g > div {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media screen and (min-width: 64rem) {
    .termin__image-wrapper {
        display: block;
        width: 100%;
        height: 100%;
        padding-top: 20px;
    }

}
