.layout-4 {
  text-align: left;
}

/*.layout-4 h2 {*/
  /*font-weight: 600;*/
  /*text-transform: uppercase;*/
  /*font-family: 'Open Sans';*/
  /*display: block;*/
  /*margin-top: 0;*/
  /*font-size: 1.6rem;*/
  /*position: relative;*/
/*}*/
.content .gallery.layout-4-images {
  justify-content: flex-start;
}

.content .gallery.layout-4-images a {
  width: calc(96% / 2);
  margin-right: 1%;
}


.layout-4-images a img {
    border: solid 2px white;
    width: auto;
    height: 100%;
    display: block;
    margin: auto;
}

.layout-4 h1 {
    margin-bottom: 40px;
    text-align: center;
}

.content .gallery.layout-4-images a {
    height: 200px;
    overflow: hidden;
    display: block;
}

@media screen and (min-width: 48rem) {
  .content .gallery.layout-4-images a {
    width: 32%;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
@media screen and (min-width: 64rem) {
  .content .gallery.layout-4-images a {
    width: 19%;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
