.kacheln {
    background: var(--color-grey-light);
}

.swiper-slide.leistungs-box {
    min-height:  490px;
    background: #fff;
}

.swiper-slide.leistungs-box h3 {
    margin: 20px 0;
    text-align: center;
}

.swiper-slide.leistungs-box h3 > a {
    margin: 20px 0;
    font-weight: 400;
}

.leistungs-box--link {
    display: inline-block;
    margin-top: 20px;
}



.boxen-leistung {
    background: white;
    padding-top: var(--gutter);
}

.boxen-leistung .boxen {
    align-content: flex-start;
    align-items: stretch;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 2rem;
}

.box-leistung {
    background: white;
    margin-bottom: 1rem;
    text-align: left;
    width: 100%;
}

.box-leistung.box-leistung--header {
    align-content: center;
    align-items: center;
    display: flex;
    height: 11.3rem;
    justify-content: center;
    margin-top: 3.5rem;
}

.box-leistung .box-leistung--image img {
    height: auto;
    width: 100%;
}

.box-leistung .box-leistung--content {
    font-size: 1.1rem;
    padding: 1rem 0.5rem;
}

.box-leistung--link {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    padding-top: 1rem;
    text-align: left;
    width: 100%;
}

.leistungs-box--image {
    height: 16rem;
}

.leistungs-box--image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}



.leistungs-box--content {
    font-weight: 300;
    padding: 20px;
    text-align: center;

}





