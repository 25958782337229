.content {
  background: white;
  /*margin: 0;*/
  /*padding-top: 2rem;*/
  /*padding-bottom: 2rem;*/
}
.text-ueber-bild--image {
  width: 100%;
}
.text-ueber-bild--text {
  position: relative;
  background: var(--color-primary);
  width: 100%;
  color: white;
  padding: 1rem
}
.text-ueber-bild--text--inner {
  border: 1px solid white;
  padding: 1rem;
  text-align: center;
}

/*.text-ueber-bild--text--inner h2 {*/
  /*color: white;*/
  /*text-transform: none;*/
  /*font-family: "Open Sans Condensed";*/
  /*font-size: 2rem;*/
/*}*/

.text-ueber-bild--text--inner .header-trenner {
  width: 50%;
  background: white;
  border-color: white;
}

@media screen and (min-width: 64rem) {
  .text-ueber-bild--image {
    width: 60%;
  }
  .text-ueber-bild--text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: var(--color-primary);
    width: 40%;
    color: white;
    right: 10%;
    padding: 1rem
  }
}
