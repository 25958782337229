header {
    background-size: auto;
    background: white;
    color: var(--font-color);
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    position: sticky;
    box-shadow: 0 2px 4px rgba(0,0,0,.4);
}

.header--wrapper {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: flex-end;
}

.header-right {
    display: block;
    position: relative;
    width: 100%;
}

.header-slogan {
    display: none;
    font-family: var(--font-special);
    text-align: right;
    font-size: 38px;
    margin-bottom: 38px;
}

.header-logo-mobil {
    margin-left: 1rem;
    padding: 0.2rem;
}

.header-logo-mobil a {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.header-logo-mobil img {
    height: 3rem;
}

.hamburger-button svg {
    width: 2rem;
    height: 2rem;
    display: block;
    margin: auto auto;
}

.hamburger-button {
    height: 2.5rem;
    width: 2.5rem;
    outline: none;
    border: medium none;
    color: #f5f5f5;
    font-weight: 700;
    padding: 0;
    cursor: pointer;
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 1rem;
}

.hamburger-button {
    background-color: var(--color-primary);
}

.hamburger-button:hover {
    background-color: color(var(--color-primary) a(80));
}

.header-logo {
    display: none;
}

.header-logo a {
    margin-left: 1rem;
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.header-logo img {
    /*height: 10rem;*/
    /*max-height: 10rem;*/
    height: 100%;
    width: auto;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

@media screen and (min-width: 48rem) {
    .header-slogan,
    .header-logo {
        display: block;
    }

    .header-right {
        margin-top: 1rem;
    }

    .header--wrapper {
        padding-bottom: 16px;
    }

}




