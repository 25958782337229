/*
 * Filename: base.css
 */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i');
* {
  position: relative;

  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  background: #fff;
}

.pure-img {
  width: 100%;
}

table {
  width: 100%;
  margin-bottom: var(--gutter);
}

th,
td {
  padding: .2rem;

  border-bottom: 1px solid var(--light-grey);
}

code {
  display: inline-block;

  padding: 0 .2em;

  color: var(--code);
  background: #eee;
}

a {
  color: var(--color-primary);
  text-decoration: none;
  transition: color 0.5s, background 0.5s;
}

a:not(.btn):hover {
  color: color(var(--color-secondary));
}


/** Firefox Glow bei invaliden Formularfeldern entfernen */
:invalid {
  box-shadow: none;
}

:-moz-submit-invalid {
  box-shadow: none;
}

:-moz-ui-invalid {
  box-shadow: none;
}

select {
  -webkit-appearance: none;
}