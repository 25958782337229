
.layout-3.content {
  text-align: left;
  background: var(--color-background-dark);
}

.layout-3 .content-right {
  margin-left: 0;
  position: relative;
  display: block;
}

.layout-3 .content-left {
  /*padding: 1rem;*/
    padding: 0 16px;
  background: transparent;
  height: 100%;
}
/*.layout-3 h2 {*/
  /*font-family: 'Papyrus';*/
  /*font-weight: 400;*/
  /*text-transform: none;*/
  /*display: block;*/
  /*margin-top: 0;*/
  /*margin-bottom: 2rem;*/
  /*position: relative;*/
/*}*/
.layout-3 .header-trenner {
  width: 100%;
  margin: auto;
  height: 1px;
  background: var(--font-color);
  margin-bottom: 1rem;
}

.layout-3 h2 {
    text-align: center;
}

@media screen and (min-width: 48rem) {
  .layout-3 .content-right {
    margin-left: 0.5rem;
  }
  .layout-3 {
    text-align: left;
  }

    .layout-3 h2 {
    text-align: left;
    }
}
