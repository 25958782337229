.kacheln {
  /*margin-top: var(--gutter);*/
    padding: 40px 0;
}

.boxen {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
}

/* slider */
.swiper-container.leistungs-swiper {
  position: relative;
}

.swiper-button-prev.leistung-button-prev {
  z-index: 40;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23a5C500'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next.leistung-button-next {
  z-index: 40;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23a5C500'%2F%3E%3C%2Fsvg%3E");
}

@media screen and (min-width: 80rem) {
  /*.swiper-container.leistungs-swiper::before {*/
    /*!*width: calc((100% - 75rem) / 2);*!*/
  /*}*/

  /*.swiper-container.leistungs-swiper::after {*/
    /*width: calc((100% - 75rem) / 2);*/
  /*}*/
}

