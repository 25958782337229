.contact {
    padding-bottom: 1rem;
    padding-top: 1rem;
    background: white;
}

.maps {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.maps-container {
    height: 25rem;
}

form#contact {
    width: 100%;

}

form#contact p:not(.status-message) {
    font-weight: 400;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
    color: #7c7c7c;
}

#label-daten {
    text-align: left;
    padding-left: 1rem;
}

#label-daten > span {
    padding-left: 1rem;
    display: inline-block;
}

.contact-div-left {
    text-align: left
}

input#contact-datenschutz {
    margin-bottom: auto;
    top: 6px;
    left: 0;
    position: absolute;
}

.contact--input-container {
    margin-bottom: 1rem;
}

.pure-form select {
    height: 2.56rem;
}

label {
    color: black;
    padding-bottom: 0;
    margin-bottom: 0;
}

.contact button {
    margin-top: 1rem;
    display: inline-block;
}

#contact-uhrzeit {
    margin-bottom: 0;
}

.status-message {
    font-weight: bold;
    font-size: var(--font-size);
    margin-top: calc(var(--gutter) * 0.5);
    color: #ffffff;
    padding: 0.5rem;
}

.status-message.error {
    background-color: var(--error);
    color: white;
}

.status-message.success {
    background-color: var(--success);
    color: white;
}

.contact {
    text-align: left;
}

.contact .content-right {
    margin-left: 0;
    position: relative;
    display: block;
    overflow: hidden;
    height: 100%;
    background-size: cover;
    background-position: left center;
    background-repeat: no-repeat;
}

.contact .content-left {
    padding: 16px 0 16px 0;
    background: white;
    height: 100%;
}

.contact label {
    font-size: 14px;
    text-align: left;
    color: var(--font-color);
}


@media screen and (min-width: 48rem) {
    .contact .content-right {
        margin-left: 0.5rem;
    }
    .contact .content-left {
        padding: 16px 16px 16px 0;
    }

    .contact .content-left.no--image {
        padding: 16px 0 16px 0;
    }
}

@media screen and (min-width: 64rem) {

}
