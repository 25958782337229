
.footer__menu {
    background: var(--color-primary);
}

.footer__menu > .container {
    height: 70px;
}

.footer__menu ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
}

.footer__menu ul > li {
    margin: 0 10px;
}

.footer__menu ul > li > a {
    color: #fff;
}

.footer__copyright {
    text-align: center;
    padding: 20px 0;
}

.footer__copyright p {
    margin: 0;
}


.footer__info > span {
    font-size: 28px;
    line-height: 32px;
}

.footer__info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}

.footer__product-info {
    padding: 20px 0;
}

@media screen and (min-width: 48rem) {
    .footer__info > span {
        margin-right: 10px;
    }
}