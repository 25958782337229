.hinweisbereich {
    background: white;
    padding: 24px 0 40px 0;
}

.pure-u-1.pure-u-md-1-2.hinweis-text {
    padding-left: 0;
}

.hinweis-box .hinweis-box--image {
    overflow: hidden;
    position: relative;
}

.hinweis-box .hinweis-box--image img {
    width: 100%;
    height: auto;
    display: block;
}

.hinweis-box .hinweis-box--image::after {
    content: ' ';
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    border: 1px solid #fff;
    z-index: 3;
}

.hinweis-boxen {
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
}

.hinweis-boxen h2,
.hinweis-boxen h3 {
    text-align: center;
}

.hinweis-boxen h2 {
    color: var(--color-primary);
    margin-bottom: 0;
}

.hinweis-boxen h3 {
    color: var(--font-color);
}

.hinweis-box {
    width: 100%;
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 1rem;
    background: white;
    border-top: 2px solid #f3f3f3;
}

#vn-bilder {
    display: block;
    position: relative;
    width: 100%;
    overflow-y: visible;
}

.vn-bilder-slide {
    width: 100%;
    display: block;
}

.hinweis-box--image-wrapper {
    display: flex;
    justify-content: flex-end;
}

.hinweisbereich .slider-content {
    height: 18rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    z-index: 2;
}

.vn-bilder-slide img {
    padding-bottom: 3rem;
}

.hinweis-box.user > .pure-g,
.hinweis-text.hinweis-text--single {
    margin-top: 32px;
}

.hinweis-box {
    border: none;
}

.hinweis-box::after {
    content: '';
    position: absolute;
    width: 300px;
    height: 1rem;
    top: -28px;
    left: 50%;
    transform: translate(-50%, 50%);
    border-top: 7px double var(--color-grey-light);
}



.hinweis-text ul {
    display: flex;
    flex-wrap: wrap;
}

.hinweis-text ul li {
    width: 100%;
}

.hinweisbereich .slider-content {
    padding: 1rem;
    height: 23rem;
    z-index: 20;
}

.hinweisbereich .slider-content button {
    display: inline-block;
    width: auto;

}

.hinweis-text {
    text-align: center;
}

@media screen and (min-width: 35.5rem) {
    .hinweis-text ul li {
        width: 50%;
    }
    .hinweis-box.user .hinweis-text {
        padding-left: 1rem;
    }

    .hinweis-text {
        text-align: left;
    }
}

/* 768 px - Tablet */
@media screen and (min-width: 48rem) {
    .pure-u-1.pure-u-md-1-2.hinweis-text {
        text-align: left;
    }
}

@media screen and (min-width: 64rem) {
    .hinweis-box {
        width: 50%;
    }
    .hinweis-box:first-child {
        padding-right: 10px;
    }

    .hinweis-box:last-child {
        padding-left: 10px;
    }
}
