.slider .swiper-slide {
    height: 200px;
    background-size: cover;
    background-position-y: 50%;
}

.slider .slider-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider .slider-content,
.slider .content-slider-wrapper .container,
.slider .content-slider-wrapper,
.slider .container-slider {
    height: 100%;
}

.slider .slider-content p {
    font-size: 1.7rem;
    line-height: 2rem;
    font-weight: 700;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .4)
}

.slider-content__outer {
    background: rgba(202, 219, 111, .6);
    padding: 10px;
    max-width: none;
    text-align: center;
    position: absolute;
    top: 100%;
    width: 100%;
    height: auto;
}

.slider-content__inner {
    background: rgba(165, 196, 0, .6);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.slider-content__inner h2 {
    color: #fff;
}

.slider-wrapper.slider h1 {
    font-size: 28px;
    line-height: 32px;
    text-shadow: 2px 2px 4px rgba(0,0,0,.4);
}

.slider-js.swiper-container {
    overflow: visible;
    margin-bottom: 180px;
}

@media screen and (min-width: 48rem) {
    .slider-js.swiper-container {
        overflow: hidden;
        margin-bottom: 0;
    }
    .slider-content__outer {
        position: static;
        height: 33%;
        width: auto;
    }

}

/* 1024 px - Laptop */
@media screen and (min-width: 64rem) {
    .slider-wrapper.slider h1 {
        font-size: 40px;
        line-height: 48px;
    }

    .slider-content__outer {
        max-width: 50%;
        margin-top: 180px;
    }

    .slider .swiper-slide {
        height: 500px;
        background-size: cover;
        background-position-y: 50%;
    }

    .slider .slider-content p {
        font-size: 3rem;
        line-height: 3.3rem;
    }
}
