/* first stage */

nav > ul {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

nav ul li {
    color: #fff;
    list-style: none;
    transition: 0.5s;
    position: static;
}

nav a {
    color: var(--font-color);
}

nav > ul > li > a {
    padding: 8px 8px;
    font-size: 16px;
    line-height: 18px;
}

nav > ul > li.active > a,
nav > ul > li.active > a:hover,
nav > ul > li:hover > a:hover,
nav > ul > li:hover > a {
    color: var(--color-primary);
}

/* second stage (the mega-menu) */

nav ul.megamenu {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    width: 85%;
    top: -9999px;
    right: 0;
    padding: 1rem;
    background: #f4f4f4;
    text-align: left;
    border-top: 2px solid var(--color-primary);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    z-index: 1;
}

nav ul li:hover ul.megamenu {
    top: 3.5rem;
}

/* third stage (child-menus in the mega-menu) */
ul.second-level {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
}

li.dropdown-list-item {
    width: 25%;
    margin-bottom: 0;
}

li.dropdown-list-item a {
    padding-bottom: 2rem;
    padding-top: 2rem;
    border-right: solid 2px white;
    border-bottom: none;
    color: var(--font-color);
}

li.dropdown-list-item a:hover {
    background: var(--color-primary);
    color: white;
}

li.dropdown-list-item:nth-of-type(4n) a {
    border-right: none;
}

li.dropdown-list-item:nth-of-type(-n+4) a {
    border-bottom: solid 2px white;
}

nav ul li:hover ul.megamenu {
    top: 40px;
}

li.dropdown-list-item {
    position: relative;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

li.dropdown-list-item:hover {
    background: #a5C500;
}

li.dropdown-list-item:hover a {
    color: #fff;
}

li.dropdown-list-item a {
    padding: 10px;
    border: none;
    width: 100%;
}

li.dropdown-list-item a:hover {
    background: transparent;
    /*     color: #828385; */
}

li.dropdown-list-item:nth-of-type(-n+4) a {
    border: none;
}

.header-logo {
    max-width: 390px;
}

.header-logo a {
    margin: 0;
}

/* 1024 px - Laptop */
@media screen and (max-width: 1023px) {

    li.dropdown-list-item {
        width: 50%;

    }
}

/* 768 px - Tablet */
@media screen and (max-width: 860px) {
    li.dropdown-list-item {
        width: 100%;

    }
}

@media screen and (max-width: 767px) {
    li.dropdown-list-item {
        height: auto;
    }

    li.dropdown-list-item:hover {
        background: transparent;
    }

    li.dropdown-list-item:hover a {
        color: #a5C500;
    }
}

@media screen and (min-width: 64rem) {
    nav > ul > li > a {
        padding: 10px 14px;
    }
}